import React from "react";
import MapHero from "../images/Heros/Map-Hero.jpg";
import MapDownload from "../documents/map.pdf";
import { Container } from "reactstrap";
import { Layout, Seo, FieldMap } from "../components";

export default function FieldMapPage() {
	const bodyText = [
		<p>
			Holding an ever-expanding 30 acre field filled with dirt trenches,
			riverbeds,
		</p>,
		<p>
			bunkers, CQB buildings, a helicopter, and ample natural cover; NiteHawk
			hosts one
		</p>,
		<p>
			of the most diverse fields for action sports in the Pacific Northwest
		</p>,
	];

	const pageData = {
		heroImg: MapHero,
		page: "",
		headerText: "Field Map",
		bodyText,
		btnText: "Book a Game",
		btnRoute: "",
		contents: "",
		defaultSport: "",
	};

	return (
		<>
			<Layout
				heroImg={pageData.heroImg}
				page={pageData.page}
				headerText={pageData.headerText}
				bodyText={pageData.bodyText}
				btnText={pageData.btnText}
				btnRoute={pageData.btnRoute}
				contents={pageData.contents}
				defaultSport={pageData.defaultSport}>
				<Seo title='Field Map' description='NiteHawk field map page' />
				{/* coming back to this later as the map is developed */}
				<Container
					className='text-center'
					style={{
						padding: "25px 0",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<FieldMap mapDownload={MapDownload} />
				</Container>
			</Layout>
		</>
	);
}
